import RightBottom from './assets/images/RightBottom.svg';
import LeftBottom from './assets/images/LeftBottom.svg';
import useDelayUnmount from './hooks/useDelayUnmount';
import RightTop from './assets/images/RightTop.svg';
import LeftTop from './assets/images/LeftTop.svg';
import Scroller from './components/Scroller';
import Logo from './assets/images/Logo.svg';
import Modal from './components/Modal';
import Footer from './assets/images/footer.png';
import Card from './components/Card';
import { useEffect, useState } from 'react';
import { q, brands } from './config/firestore';
import { getDocs } from 'firebase/firestore';
import { getStorage, ref, getDownloadURL } from 'firebase/storage';

function App() {
	const [isModalOpen, setIsModalOpen] = useState(null);
	const [isMounted, setIsMounted] = useState(false);
	const [packs, setPacks] = useState([]);
	const [brandImages, setBrandImages] = useState([]);
	const showSlider = useDelayUnmount(isMounted, 100);
	const storage = getStorage();

	useEffect(() => {
		const fetchLinks = async () => {
			const snapshot = await getDocs(q);
			const snapshot2 = await getDocs(brands);
			const packs = snapshot.docs.map(i => {
				return i.data();
			});
			const brandImages = snapshot2.docs.map(i => {
				return i.data();
			});
			setBrandImages(brandImages);
			setPacks(packs);
		};
		void fetchLinks();
	}, []);

	return (
		<div>
			<div className='relative max-w-full bg-yellow h-[640px] 2xl:h-[840px] flex flex-col items-center justify-between text-white py-[20px] px-[10px]'>
				<img
					className='absolute bottom-0 right-0 w-[120px] sm:w-fit'
					src={RightBottom}
				/>
				<img
					className='absolute top-4 right-4'
					src={RightTop}
				/>
				<img
					className='absolute bottom-0 left-0 w-[120px] sm:w-fit'
					src={LeftBottom}
				/>
				<img
					className='absolute top-4 left-4'
					src={LeftTop}
				/>

				<img src={Logo} />

				<div className='max-w-[640px] flex flex-col gap-[30px] overflow-hidden'>
					<h1 className='text-[40px] sm:text-[64px] text-center font-black leading-none md:leading-tight uppercase'>Өгсөн авсандаа, Өлзийтэй байг</h1>
					<p className='text-sm sm:text-[20px] text-center font-pro overflow-hidden leading-normal'>
					“Үзэсгэлэн болгогч" хэмээх харагчин туулай жилийн Цагаан сарын баярыг тохиолдуулан “Өлзийтэй” нийгэмлэгээс шинэлж буй олондоо хүртээмжтэй, үндэсний үйлдвэрлэлдээ дэмтэй бэлгийн богцуудыг бэлтгэн гаргаж байна.

					</p>
				</div>

				<div className='flex flex-col space-y-2'>
					<div>Хамтрагч байгууллагууд:</div>
					<Scroller data={brandImages} />
				</div>
			</div>

			<div className='z-10 grid bg-white grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 gap-5 p-[20px]'>
				{packs?.map((item, index) => {
					return (
						<Card
							key={index}
							onClick={() => {
								setIsModalOpen(item);
								setIsMounted(true);
							}}
							{...item}
						/>
					);
				})}
			</div>

			{isModalOpen && (
				<Modal
					onCancel={() => setIsMounted(false)}
					showSlider={showSlider}
					{...isModalOpen}
				/>
			)}
			<div className='flex items-center justify-center w-full mt-20'>
				<img
					src={Footer}
					className='w-auto h-20'
				/>
			</div>
		</div>
	);
}

export default App;
