import { gsap } from 'gsap';
import { useEffect, useRef, useState } from 'react';

const Scroller = ({ size, data }) => {
	const wrapper = useRef();
	const boxes = useRef();
	const box = gsap.utils.selector(boxes);

	useEffect(() => {
		if (boxes.current.firstChild) {
			const boxWidth = boxes.current.firstChild.offsetWidth + 50;
			const boxHeigth = boxes.current.firstChild.offsetHeight;
			const totalWidth = boxWidth * (data?.length - 1);

			gsap.set(box('.infinite-loop-slide'), {
				x: i => i * boxWidth,
				y: i => i * -boxHeigth,
			});

			gsap.to(box('.infinite-loop-slide'), {
				duration: boxWidth * 0.3,
				x: '-=' + (totalWidth + boxWidth),
				paused: false,
				ease: 'none',
				repeat: -1,
				modifiers: { x: gsap.utils.unitize(gsap.utils.wrap(-boxWidth, totalWidth)) },
			});
		}
	}, [size, data]);

	return (
		<div
			ref={wrapper}
			className='flex flex-col col-span-12 justify-center'
			id='pp'
		>
			<div
				ref={boxes}
				className='max-h-16 flex-nowrap overflow-hidden rounded-full border border-white border-opacity-25'
			>
				{data?.map((i, index) => {
					return (
						<div
							key={i + index}
							className='relative flex-shrink-0 h-16 infinite-loop-slide w-14 flex items-center p-1'
						>
							<img
								className='h-full object-contain'
								src={i.image}
							/>
						</div>
					);
				})}
			</div>
		</div>
	);
};

export default Scroller;
