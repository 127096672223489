import { useState } from 'react';
import { AiOutlineMinus } from 'react-icons/ai';
import { HiOutlineChevronRight } from 'react-icons/hi';

const Modal = ({ onCancel, image, name, description, url, showSlider, price, items }) => {
	return (
		<div
			onClick={onCancel}
			className={`-z-10 opacity-0 fixed inset-0 justify-center flex bg-black/50 bg-opacity-90 overflow-scroll items-start sm:items-center transition transform duration-700 ease-in-out ${
				showSlider && '!opacity-100 !z-20'
			}`}
		>
			<div
				onClick={e => e.stopPropagation()}
				className='w-[90%] sm:w-[460px] min-h-[640px] bg-white flex flex-col rounded-lg overflow-hidden'
			>
				<div className='bg-gray relative flex justify-center items-center h-[240px]'>
					<img
						src={image}
						className='h-full'
					/>
					<div
						onClick={onCancel}
						className='absolute top-4 right-4 cursor-pointer bg-white px-[8px] py-[8px] rounded-md'
					>
						<AiOutlineMinus />
					</div>
				</div>
				<div className='grow-[1] p-[20px]'>
					<p className='text-[24px] font-black '>{name} </p>
					<p className='text-[16px] font-menium max-w-[84%] text-gray-100 font-pro'>{description}</p>
					<div className='py-[20px] flex flex-col gap-2 mb-3 border-b border-dashed border-[#ECECEC] '>
						<p className='text-[16px] font-menium max-w-[84%] text-gray-100 font-pro'>Богц дотор:</p>
						{items?.map(({ name, count }) => {
							return (
								<div className='justify-between flex text-[16px] font-bold'>
									<h3 >{name}</h3>
									<p className='text-[#BCBCBC] min-w-[30px] text-right'>{count}ш</p>
								</div>
							);
						})}
					</div>
					<div className='flex items-center justify-between'>
						<p>Үнэ:</p>
						<p className='text-[32px] font-black text-yellow'>{new Intl.NumberFormat().format(price)}₮</p>
					</div>
				</div>
				<a
					href={url}
					className='text-[16px] cursor-pointer font-bold h-[60px] text-white uppercase bg-yellow flex flex-row justify-center items-center relative space-x-5 group'
					target='_blank'
				>
					<div>худалдаж авах</div>
					<div className='transition duration-300 ease-in-out transform group-hover:translate-x-2'>
						<HiOutlineChevronRight size={20} />
					</div>
				</a>
			</div>
		</div>
	);
};

export default Modal;
