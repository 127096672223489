/* eslint-disable jsx-a11y/alt-text */

import CardBackground from '../assets/images/CardBackground.svg';

const Card = ({ items, onClick, price, image, name, description }) => {
	return (
		<div
			onClick={onClick}
			className='relative flex flex-col overflow-hidden rounded-md cursor-pointer group bg-gray'
		>
			<div className='relative flex flex-col gap-5 p-10 overflow-hidden grow sm:gap-10'>
				<img
					src={CardBackground}
					className='opacity-0 group-hover:opacity-100 blur-sm group-hover:blur-none translate-y-40 group-hover:translate-y-0 inset-x-0 absolute -bottom-[120px] transition duration-500 ease-out'
				/>

<img
						className='flex items-center justify-center object-contain w-auto mx-auto transition duration-500 ease-in-out h-60 group-hover:scale-110'
						src={image}
					/>
				<p className='text-2xl font-black text-center'>{name}</p>
				<p className='font-medium text-center text-md text-text-gray font-pro'>{description}</p>
				<div className='flex justify-center gap-[10px] m-auto flex-wrap max-w-[270px] md:max-w-full'>
					{items?.map((item, index) => {
						return (
							<img key={index} src={item.image} className='w-10 bg-white rounded-full aspect-square md:w-max'/>
						);
					})}
				</div>
			</div>

			<div className=' bg-yellow-secondary group-hover:bg-yellow flex flex-col  transition duraiton-500 max-h-[70px] cursor-pointer overflow-hidden'>
				<div className='text-yellow min-h-[70px] text-[32px] font-extrabold flex group-hover:-translate-y-full translate-y-0 transition duration-300 ease-in-out items-center justify-center'>
					{new Intl.NumberFormat().format(price)}₮
				</div>
				<div className='text-white min-h-[70px] text-[32px] font-extrabold flex group-hover:-translate-y-full text-lg translate-y-0 transition duration-300 ease-in-out items-center justify-center'>
					Дэлгэрэнгүй
				</div>
			</div>
		</div>
	);
};

export default Card;
