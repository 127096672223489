// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore, collection, query, where } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyBAjarE-rJF757u61TIofJzXZb9JsG-v_A",
  authDomain: "olzii-e5fc0.firebaseapp.com",
  projectId: "olzii-e5fc0",
  storageBucket: "olzii-e5fc0.appspot.com",
  messagingSenderId: "880342055911",
  appId: "1:880342055911:web:803ea669fb2673d711db42",
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

const packsRef = collection(db, 'packs');
const brandsRef = collection(db, 'partners')

const brands = query(brandsRef)
const q = query(packsRef);

export {q, brands}